import React from 'react'
import './style.css';

export default function Splitter() {
  return (
    <div className='splitter--cont'>
          <div className='splitter'>

          </div>
    </div>
  )
}
